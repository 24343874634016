/* For devices smaller than 768px */
@media only screen and (max-width: 767px) {
    .video {
        width: 100%;
        height: 250px;

    }
  }
.video {
    box-shadow: 0 0 10px var(--color-primary);

}
.video:hover {
    box-shadow: 0 0 20px var(--color-primary);

}
