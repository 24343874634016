.portfolio__container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2.5rem;
}

.portfolio__item {
  background: var(--color-bg-variant);
  padding: 1.3rem;
  border-radius: 2rem;
  border: 1px solid transparent;
  transition: var(--transition);
  height: 100%; /* It's also important to have a defined height */
  box-shadow: 0 0 10px var(--color-primary);


}

.portfolio__item:hover {
  border-color: var(--color-primary-variant);
  background: transparent;
  box-shadow: 0 0 20px var(--color-primary);

}

.portfolio__item-image {
  border-radius: 1.5rem;
  overflow: hidden;
  height: 200px; /* adjust based on your needs */
  object-fit: cover; /* to maintain the aspect ratio of the image */

}

.portfolio__item h3 {
  margin: 1.2rem 0 2rem;
}

.portfolio__item-cta {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
    margin-top: auto;

}
.portfolio__item-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* ================= MEDIA QUERIES (MEDIUM DEVICES) ================= */
@media screen and (max-width: 1024px) {
  .portfolio__container {
    grid-template-columns: 1fr 1fr;
    gap: 1.2rem;
  }
}

/* ================= MEDIA QUERIES (SMALL DEVICES) ================= */
@media screen and (max-width: 600px) {
  .portfolio__container {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
}
