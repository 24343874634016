/* Example CSS for the .name class to animate text */
@keyframes textFade {
  0%,
  100% {
    background-size: 200% 200%;
    background-position: left center;
  }
  50% {
    background-size: 200% 200%;
    background-position: right center;
  }
}

.name {
  /* Apply your animation */
  animation: textFade 5s ease infinite;

  /* Gradient background */
  background-image: linear-gradient(to right, #4fd1c5, #805ad5, #f6ad55);

  /* Making the text transparent so the background is visible */
  color: transparent;

  /* Additional styling */
  background-clip: text;
  -webkit-background-clip: text;
  display: inline-block;
  font-weight: 900; /* Corresponds to 'font-black' in Tailwind */
  margin-right: 30px
}
.name-logo-container {
  display: flex;
  align-items: center; /* Align items vertically in the center */
  justify-content: center; /* Center the logo */
}
.logo {
  width: 100px;
  height: 100px;
}
header {
  height: 100vh;
  padding-top: 7rem;
  overflow: scroll;
}

.header__container {
  text-align: center;
  height: 100%;
  position: relative;

}

/* ==================== CTA ===================== */
.cta {
  margin-top: 2.5rem;
  display: flex;
  gap: 1.2rem;
  justify-content: center;
}

/* ==================== HEADER SOCIALS ===================== */
.header__socials {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  position: absolute;
  left: 0;
  bottom: 3rem;
}

.header__socials::after {
  content: "";
  width: 1px;
  height: 2rem;
  background: var(--color-primary);
}

/* ==================== ME IMAGE ===================== */
.me {
  background: linear-gradient(var(--color-primary), transparent);
  width: 22rem;
  height: 30rem;
  position: absolute;
  left: calc(50% - 11rem);
  margin-top: 4rem;
  border-radius: 12rem 12rem 0 0;
  overflow: hidden;
  padding: 5rem 1.5rem 1.5rem 1.5rem;
  box-shadow: 0 0 10px var(--color-primary);
}

/* ==================== tryout ===================== */
.me:hover {
  background: linear-gradient(
    45deg,
    transparent,
    var(--color-primary),
    transparent
  );
  box-shadow: 0 0 20px var(--color-primary);
}

/* ==================== SCROLL DOWN ===================== */
.scroll__down {
  position: absolute;
  right: -2.3rem;
  bottom: 5rem;
  transform: rotate(90deg);
  font-weight: 300;
  font-size: 0.9rem;
}

/* ================= MEDIA QUERIES (MEDIUM DEVICES) ================= */
@media screen and (max-width: 1024px) {
  header {
    height: 100vh;
  }
}

/* ================= MEDIA QUERIES (SMALL DEVICES) ================= */
@media screen and (max-width: 600px) {
  header {
    height: 100vh;
  }
  .header__socials,
  .scroll__down {
    display: none;
  }
}
